<template>
  <div>
    <ac-module-heading> FATCA </ac-module-heading>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <ac-button title="Download FATCA Report Example" :icon="icons.mdiDownload"></ac-button>
          <ac-button
            title="Upload FATCA"
            :icon="icons.mdiUpload"
            @click="showUpload = true"
          ></ac-button>
        </div>
        <v-switch
          v-model="convertToggle"
          color="primary"
          label="Convert only. Do not save for future references."
          hide-details
        ></v-switch>
      </v-card-text>
      <v-data-table
        :headers="tableHeader"
        :items="tableData"
        :loading="loading"
        class="text-no-wrap"
      >
        <template #[`item.xls`]>
          <ac-icon-btn text="Download" :icon="icons.mdiDownload" color="info" />
        </template>
        <template #[`item.xml`]>
          <ac-icon-btn text="Download" :icon="icons.mdiDownload" color="info" />
        </template>
        <template #[`item.actions`]>
          <ac-icon-btn text="Create XML" :icon="icons.mdiPlus" color="primary" />
          <ac-icon-btn
            text="Create XML"
            :icon="icons.mdiDeleteOutline"
            color="error"
            btn-class="mx-2"
          />
          <ac-icon-btn text="View" :icon="icons.mdiEye" color="success" />
        </template>
      </v-data-table>
    </v-card>
    <upload-modal v-model="showUpload" title="FATCA Report" />
  </div>
</template>

<script>
import AcButton from '@/components/AcButton.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'
import UploadModal from '@/components/modals/UploadModal.vue'

import { mdiDownload, mdiPlus, mdiDeleteOutline, mdiEye, mdiUpload } from '@mdi/js'

export default {
  name: 'FATCA',
  components: {
    AcButton,
    AcIconBtn,
    UploadModal,
  },
  data: () => ({
    icons: {
      mdiDownload,
      mdiPlus,
      mdiDeleteOutline,
      mdiEye,
      mdiUpload,
    },
    showUpload: false,
    convertToggle: false,
    loading: false,
    tableHeader: [
      { text: 'UPLOADER', value: 'uploader' },
      { text: 'XLS LINK', value: 'xls' },
      { text: 'XML LINK', value: 'xml' },
      { text: 'DATE GENERATED', value: 'date' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ],
    tableData: [
      {
        uploader: 'julian@alphacalibration.com',
        date: '28 Aug 2017',
      },
    ],
  }),
}
</script>

<style lang="scss" scoped></style>
